.myua-icon {
  font-family: 'myua-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.myua-icon-social-wiki:before {
  content: '\e93e';
}

.myua-icon-social-google:before {
  content: '\e932';
}

.myua-icon-social-youtube:before {
  content: '\e91c';
}

.myua-icon-social-vkontakte:before {
  content: '\e91e';
}

.myua-icon-social-twitter:before {
  content: '\e91f';
}

.myua-icon-social-ok:before {
  content: '\e920';
}

.myua-icon-social-lj:before {
  content: '\e921';
}

.myua-icon-social-instagram:before {
  content: '\e922';
}

.myua-icon-social-facebook:before {
  content: '\e923';
}

.myua-icon-bus-sign:before {
  content: '\e940';
}

.myua-icon-metro-sign:before {
  content: '\e941';
}

.myua-icon-location-pin:before {
  content: '\e942';
}

.myua-icon-bank:before {
  content: '\e943';
}

.myua-icon-payment:before {
  content: '\e944';
}

.myua-icon-phone:before {
  content: '\e945';
}

.myua-icon-car:before {
  content: '\e93f';
}

.myua-icon-compatibility:before {
  content: '\e93e';
}

.myua-icon-business:before {
  content: '\e93d';
}

.myua-icon-thumb-down:before {
  content: '\e93c';
}

.myua-icon-more:before {
  content: '\e93b';
}

.myua-icon-country:before {
  content: '\e93a';
}

.myua-icon-filter:before {
  content: '\e939';
}

.myua-icon-bookmark-filled:before {
  content: '\e936';
}

.myua-icon-bookmark:before {
  content: '\e937';
}

.myua-icon-headphones:before {
  content: '\e938';
}

.myua-icon-pin:before {
  content: '\e934';
}

.myua-icon-pin-filled:before {
  content: '\e935';
}

.myua-icon-article:before {
  content: '\e933';
}

.myua-icon-quotes:before {
  content: '\e931';
}

.myua-icon-exit:before {
  content: '\e930';
}

.myua-icon-user:before {
  content: '\e92f';
}

.myua-icon-eye:before {
  content: '\e92e';
}

.myua-icon-photo:before {
  content: '\e92d';
}

.myua-icon-video:before {
  content: '\e92c';
}

.myua-icon-share:before {
  content: '\e928';
}

.myua-icon-share-branch:before {
  content: '\e929';
}

.myua-icon-favorite:before {
  content: '\e92a';
}

.myua-icon-like:before {
  content: '\e92b';
}

.myua-icon-time:before {
  content: '\e927';
}

.myua-icon-gear:before {
  content: '\e926';
}

.myua-icon-mark:before {
  content: '\e925';
}

.myua-icon-search:before {
  content: '\e91d';
}

.myua-icon-flash:before {
  content: '\e924';
}

.myua-icon-mail:before {
  content: '\e900';
}

.myua-icon-bell:before {
  content: '\e901';
}

.myua-icon-message:before {
  content: '\e902';
}

.myua-icon-city:before {
  content: '\e903';
}

.myua-icon-cloud-day:before {
  content: '\e904';
}

.myua-icon-cloud-night:before {
  content: '\e905';
}

.myua-icon-cloud:before {
  content: '\e906';
}

.myua-icon-fog:before {
  content: '\e907';
}

.myua-icon-hailstones:before {
  content: '\e908';
}

.myua-icon-lightning:before {
  content: '\e909';
}

.myua-icon-moon:before {
  content: '\e90a';
}

.myua-icon-rain-snow:before {
  content: '\e90b';
}

.myua-icon-rain:before {
  content: '\e90c';
}

.myua-icon-snow:before {
  content: '\e90d';
}

.myua-icon-sun:before {
  content: '\e90e';
  color: #ffeb3b;
}

.myua-icon-wind:before {
  content: '\e90f';
}

.myua-icon-aquarius:before {
  content: '\e910';
}

.myua-icon-aries:before {
  content: '\e911';
}

.myua-icon-cancer:before {
  content: '\e912';
}

.myua-icon-capricorn:before {
  content: '\e913';
}

.myua-icon-gemini:before {
  content: '\e914';
}

.myua-icon-leo:before {
  content: '\e915';
}

.myua-icon-libra:before {
  content: '\e916';
}

.myua-icon-pisces:before {
  content: '\e917';
}

.myua-icon-sagittarius:before {
  content: '\e918';
}

.myua-icon-scorpio:before {
  content: '\e919';
}

.myua-icon-taurus:before {
  content: '\e91a';
}

.myua-icon-virgo:before {
  content: '\e91b';
}
