@font-face {
  font-family: 'Euclid';
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url('../../public/files/fonts/euclidflex-bold.woff2') format('woff2'),
    url('../../public/files/fonts/euclidflex-bold.woff') format('woff');
}

@font-face {
  font-family: 'Euclid';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url('../../public/files/fonts/euclidflex-medium.woff2') format('woff2'),
    url('../../public/files/fonts/euclidflex-medium.woff') format('woff');
}

@font-face {
  font-family: 'myua-icons';
  src: url('../../public/files/fonts/myua-icons.eot?jzqvqy');
  src: url('../../public/files/fonts/myua-icons.eot?jzqvqy#iefix')
      format('embedded-opentype'),
    url('../../public/files/fonts/myua-icons.ttf?jzqvqy') format('truetype'),
    url('../../public/files/fonts/myua-icons.woff?jzqvqy') format('woff'),
    url('../../public/files/fonts/myua-icons.svg?jzqvqy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

body,
html,
#__next {
  height: 100%;
}

body {
  font: 400 16px/1.5 Roboto, sans-serif;
  color: #111;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

a,
i {
  transition: color 0.2s;
}
a {
  color: #2196f3;
  text-decoration: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
  color: transparent;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

#iframeMyUa {
  position: absolute;
  visibility: hidden;
}

.google-auto-placed {
  max-width: 100%;
}